.app iframe {
  width: 100%;
  height: 100vh;
}

.text-right {
  text-align: right;
}
.ttn-row {
  border-bottom: 1px dashed;
}
.ttn-row:nth-child(3n + 3) {
  border-bottom: 0;
}
.ttn-row .col {
  padding-bottom: 10px;
  padding-top: 10px;
}
.ttn-row > .col:first-child {
  border-right: 1px dashed;
}

.ttn-row table thead, .ttn-row table tbody tr {
  border-color: #212529
}
.ttn-row .table>:not(:first-child) {
  border-top: 1px solid currentColor;
}

.dropzone {
  text-align: center;
  padding: 100px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  margin-bottom: 20px;
  cursor: pointer;
  margin-top: 20px;
}

.accept {
  border-color: #107c10 !important;
}

.reject {
  border-color: #d83b01 !important;
}

@media print {
  @page {
    font-size: 10px;
    /* margin-top: 0.3cm;
    margin-bottom: 0.3cm; */
    margin-top: 0.6cm;
    margin-bottom: 0cm;
    margin-left: 0.2cm;
    margin-right: 0.2cm;
  }
  .ttn-row {
    page-break-inside: avoid;
  }
  .num_of_days {
    width: 100px;
  }
  .ttn-row .col {
    /* padding-bottom: 2px;
    padding-top: 2px; */
    padding-bottom: 10px;
    padding-top: 10px;
  }
}